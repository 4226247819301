import { loadStripe } from "@stripe/stripe-js";
import { getSiteConfig } from "./api";
import {
  Stripe,
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
  StripeAddressElementOptions,
  StripeAddressElement,
  StripeExpressCheckoutElementOptions,
  StripeExpressCheckoutElement,
} from "@stripe/stripe-js";
import { apperanceConfig } from "./stripeElementApperance";

/**
 * StripeSdk class for handling Stripe integration
 */
export class StripeSdk {
  private static instance: StripeSdk;
  public stripe: Stripe | null = null;
  public elements: StripeElements | undefined;
  private publicKey: string | null = "";
  private connectId: string | null = "";

  private constructor() {}

  /**
   * Initialize the StripeSdk instance
   * @param siteId - The site ID
   * @returns Promise<StripeSdk> - The initialized StripeSdk instance
   */
  public static async initialize(siteId: string): Promise<StripeSdk> {
    if (!StripeSdk.instance) {
      StripeSdk.instance = new StripeSdk();
      await StripeSdk.instance.setup(siteId);
    }
    return StripeSdk.instance;
  }

  /**
   * Set up the Stripe instance
   * @param siteId - The site ID
   */
  private async setup(siteId: string): Promise<void> {
    const { publicKey, connectId } = await this.getConfig(siteId);
    if (!publicKey) {
      console.error("No public key found");
      return;
    }
    this.stripe = await loadStripe(publicKey, {
      stripeAccount: connectId || undefined,
    });
    if (!this.stripe) {
      console.error("Failed to initialize Stripe");
      return;
    }
    let element = document.querySelector("[data-stripe-style-source]");
    if (!element) {
      element = document.querySelector("[data-stripe-field]");
    }
    //get first input in the DOM
    element = document.querySelector("input");
    if (!element) {
      console.error("No element found to mount Stripe Elements");
    }

    this.elements = this.stripe.elements({
      locale: "en",
      appearance: apperanceConfig(element as HTMLElement) as any,
    });
  }

  /**
   * Get the Stripe configuration
   * @param siteId - The site ID
   * @returns Promise<{ publicKey: string | null; connectId: string | null }>
   */
  private async getConfig(
    siteId: string
  ): Promise<{ publicKey: string | null; connectId: string | null }> {
    if (this.publicKey) {
      return {
        publicKey: this.publicKey,
        connectId: this.connectId,
      };
    }
    const response = await getSiteConfig(siteId);
    this.publicKey = response.publicKey;
    this.connectId = response.connect_id;
    return {
      publicKey: this.publicKey,
      connectId: this.connectId,
    };
  }

  /**
   * Create a Stripe Card Element
   * @param element - The HTML element to mount the Card Element
   * @param options - Optional StripeCardElementOptions
   * @returns StripeCardElement
   */
  public createCardElement(
    element: HTMLElement,
    options?: StripeCardElementOptions
  ): StripeCardElement {
    if (!this.elements) {
      throw new Error("Stripe not initialized");
    }
    if (!options) {
      options = {
        classes: {
          empty: "",
          base: "",
          complete: "",
          invalid: "",
        },
        style: {
          base: {
            color: "black",
            fontFamily: "sans-serif",
            fontSize: "16px",
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "400",
            letterSpacing: "0px",
            lineHeight: "1.5",
            textAlign: "start",
            textTransform: "none",
            padding: "0px",
            textDecoration: "none",
            textShadow: "none",
          },
        },
      };
    }
    const cardElement = this.elements.create(
      "card",
      options
    ) as StripeCardElement;
    if (!cardElement) {
      throw new Error("Failed to create card element");
    }
    cardElement.mount(element);
    return cardElement;
  }

  /**
   * Create a Stripe Address Element
   * @param element - The HTML element to mount the Address Element
   * @param options - Optional StripeAddressElementOptions
   * @returns StripeAddressElement
   */
  public createAddressElement(
    element: HTMLElement,
    options?: StripeAddressElementOptions
  ): StripeAddressElement {
    if (!this.elements) {
      throw new Error("Stripe not initialized");
    }

    const addressElement = this.elements.create(
      "address",
      options as StripeAddressElementOptions
    ) as StripeAddressElement;
    if (!addressElement) {
      throw new Error("Failed to create address element");
    }
    addressElement.mount(element);
    return addressElement;
  }

  /**
   * Create a Stripe Express Checkout Element
   * @param element - The HTML element to mount the Express Checkout Element
   * @param options - Optional StripeExpressCheckoutElementOptions
   * @returns StripeExpressCheckoutElement
   */
  public createExpressCheckoutElement(
    element: HTMLElement,
    options?: StripeExpressCheckoutElementOptions
  ): StripeExpressCheckoutElement {
    if (!this.elements) {
      throw new Error("Stripe not initialized");
    }
    const expressCheckoutElement = this.elements.create(
      "expressCheckout",
      options
    ) as StripeExpressCheckoutElement;
    if (!expressCheckoutElement) {
      throw new Error("Failed to create express checkout element");
    }
    expressCheckoutElement.mount(element);
    return expressCheckoutElement;
  }

  /**
   * Submit the Stripe Elements form
   * @returns Promise<{ error?: Error }>
   */
  public submit() {
    if (!this.elements) {
      throw new Error("Stripe not initialized");
    }
    return this.elements.submit();
  }
}
