import { fetchFromServer, postToServer } from "./fetchUtils";

//Get Stripe Config
export async function getSiteConfig(siteId: string): Promise<any> {
  return await fetchFromServer(`/${siteId}`);
}

//Get Stripe Price
export async function getStripePrice(
  siteId: string,
  priceId: string
): Promise<any> {
  return await fetchFromServer(`/${siteId}/price/${priceId}`);
}

//Create Payment Intent
export async function createPaymentIntent(
  siteId: string,
  amount: number,
  currency: string,
  description?: string,
  metadata?: { [key: string]: string }
): Promise<any> {
  return await postToServer(`/${siteId}/payment-intent`, {
    amount,
    currency,
    metadata,
    description,
  });
}

//Confirm Payment Intent
export async function confirmPaymentIntent(
  siteId: string,
  paymentIntentId: string
): Promise<any> {
  return await postToServer(`/${siteId}/payment-intent/confirm`, {
    paymentIntentId,
  });
}
