import { Thind } from "thind-js";
import {
  StripeCardElement,
  StripeAddressElementOptions,
  StripeExpressCheckoutElementOptions,
  StripeExpressCheckoutElement,
} from "@stripe/stripe-js";
import { FormPaymentsSDK } from "../sdk";
const thind = new Thind();

import { createMetadata, verifyRequiredFields } from "./webflowFormUtils";

export async function initStripeCardElements(formPaymentsSDK: FormPaymentsSDK) {
  const cardElements = document.querySelectorAll(
    "[data-stripe-element='card']"
  );
  for (const cardElement of cardElements) {
    const form = cardElement.closest("form");
    let styleSourceInput = form?.querySelector("[data-stripe-style-source]");
    if (styleSourceInput == null) {
      styleSourceInput = document.querySelector("input");
    }
    if (!styleSourceInput) {
      styleSourceInput = cardElement;
    }
    if (!styleSourceInput) {
      console.error(
        "style source input not found for card element",
        cardElement
      );
      continue;
    }
    if (!form) {
      console.error("form not found for card element", cardElement);
    }
    let baseClass = styleSourceInput.classList.value || "input";
    let completeClass =
      styleSourceInput.getAttribute("data-complete-class") || "valid";
    let errorClass =
      styleSourceInput.getAttribute("data-error-class") || "error";
    var styles = window.getComputedStyle(styleSourceInput);
    let baseStyle = {
      color: styles.color,
      fontFamily: styles.fontFamily,
      fontSize: styles.fontSize,
      fontStyle: styles.fontStyle,
      fontVariant: styles.fontVariant,
      fontWeight: styles.fontWeight,
      letterSpacing: styles.letterSpacing,
      lineHeight: styles.lineHeight,
      textAlign: styles.textAlign,
      textTransform: styles.textTransform,
      padding: styles.padding,
      textDecoration: styles.textDecoration,
      textShadow: styles.textShadow,
    };
    var options = {
      classes: {
        empty: baseClass,
        base: baseClass,
        complete: `${baseClass} ${completeClass}`,
        invalid: `${baseClass} ${errorClass}`,
      },
      style: {
        base: baseStyle,
      },
    };
    const stripeElement = formPaymentsSDK.createCardElement(
      cardElement as HTMLElement,
      options
    );
    HandleWebflowCardForm(
      form as HTMLFormElement,
      stripeElement,
      formPaymentsSDK
    );
  }
}

export async function initStripeAddressElement(
  formPaymentsSDK: FormPaymentsSDK
) {
  const addressElements = document.querySelectorAll(
    "[data-stripe-element='address']"
  );
  for (const addressElement of addressElements) {
    //get options from data attributes
    const allAttributes = addressElement.attributes;
    let options: any = {};
    for (const attribute of allAttributes) {
      if (attribute.nodeName.startsWith("data-address-element-")) {
        const name = attribute.nodeName
          .replace("data-address-element-", "")
          .replace("_", ".");
        options[name] = attribute.nodeValue;
      }
    }
    HandleWebflowAddressElements(
      addressElement as HTMLElement,
      options,
      formPaymentsSDK as FormPaymentsSDK
    );
  }
}

export async function initStripeExpressElements(
  formPaymentsSDK: FormPaymentsSDK
) {
  let expressElements = document.querySelectorAll(
    "[data-stripe-element='express-checkout']"
  );
  if (expressElements.length == 0) {
    expressElements = document.querySelectorAll(
      "[data-stripe-element='paymentRequestButton']"
    );
  }
  for (const expressElement of expressElements) {
    if ((expressElement as HTMLElement).style.display !== "block") {
      (expressElement as HTMLElement).style.display = "block";
      console.log("Upding v1 attributes to v2");
    }
    let options: any = {};
    const allAttributes = expressElement.attributes;
    for (const attribute of allAttributes) {
      if (attribute.nodeName.startsWith("data-express-element-")) {
        const name = attribute.nodeName
          .replace("data-express-element-", "")
          .replace("_", ".");
        options[name] = attribute.nodeValue;
      }
    }
    HandleWebflowExpressElements(
      expressElement as HTMLElement,
      options,
      formPaymentsSDK as FormPaymentsSDK
    );
  }
}

export async function HandleWebflowCardForm(
  form: HTMLFormElement,
  stripeElement: StripeCardElement,
  formPaymentsSDK: FormPaymentsSDK
) {
  formPaymentsSDK.webflowFormIntance = {
    form: form,
    type: "card",
    priceType: "dynamic",
    SubmitButtoninitialText: "Submit",
    SubmitButtonWaitingText: "Processing...",
    SubmitButtonSuccessText: "Payment successful",
  };
  thind.form.disable(form);
  const submitButton = form.querySelector(
    'input[type="submit"]'
  ) as HTMLInputElement | null;
  const submitButtonInverHtml = submitButton?.value || "Process Payment";
  const submitButtonWaitingHtml =
    submitButton?.getAttribute("data-wait") || "Processing...";
  submitButton?.setAttribute("data-default-value", submitButtonInverHtml || "");
  const successButtonText =
    submitButton?.getAttribute("data-success") || "Payment successful";

  formPaymentsSDK.webflowFormIntance = {
    form: form,
    type: "card",
    SubmitButtoninitialText: submitButtonInverHtml,
    SubmitButtonWaitingText: submitButtonWaitingHtml,
    SubmitButtonSuccessText: successButtonText,
  };

  listenToPayments(formPaymentsSDK);

  //Add event listener to the stripe element
  stripeElement.on("change", (event) => {
    if (event.error !== undefined) {
      thind.form.error(form, event.error.message);
    }
    if (event.error == undefined) {
      thind.form.errorHide(form);
    }
  });

  const stripePriceId = form.getAttribute("data-price-id");
  if (!stripePriceId) {
    console.error("No stripe price id found");
    return;
  }
  //remove the price id from the attribute
  form.removeAttribute("data-price-id");
  await formPaymentsSDK.getPrice(stripePriceId);
  initStripeExpressElements(formPaymentsSDK);

  form.addEventListener("submit", async (event) => {
    event.preventDefault();

    try {
      const result = await formPaymentsSDK.submit();
      if (result.error) {
        console.error(result.error);
        thind.form.error(form, result.error.message || "Error");
        return; // This will now properly exit the function
      }
    } catch (error) {
      console.error(error);
      thind.form.error(
        form,
        error instanceof Error ? error.message : String(error)
      );
      return; // This will now properly exit the function
    }

    thind.form.changeSubmitButton(form, submitButtonWaitingHtml, true);
    // create payment intent
    const metadata = createMetadata(form) || {};
    const serverPaymentIntent = await formPaymentsSDK.createPaymentIntent(
      metadata
    );
    if (serverPaymentIntent.error) {
      console.error(serverPaymentIntent.error);
      thind.form.error(form, serverPaymentIntent.error);
      thind.form.changeSubmitButton(form, submitButtonInverHtml, false);
      return;
    }
    if (serverPaymentIntent.id) {
      thind.form.changeSubmitButton(form, submitButtonWaitingHtml, false);

      //Get a shipping address element
      formPaymentsSDK.getAddress();
      try {
        await formPaymentsSDK.confirmCardPayment(
          serverPaymentIntent.client_secret,
          {
            payment_method: {
              card: stripeElement,
              billing_details:
                formPaymentsSDK.webflowFormIntance.billingAddress,
            },
            shipping: formPaymentsSDK.webflowFormIntance.shippingAddress,
          }
        );
      } catch (error) {
        console.error(error);
        thind.form.error(form, "Error creating payment intent");
        thind.form.changeSubmitButton(form, submitButtonInverHtml, false);
        return;
      }
    }
  });
}

//handle paymeny success and payment fail
function listenToPayments(formPaymentsSDK: FormPaymentsSDK) {
  if (!formPaymentsSDK.webflowFormIntance) {
    console.error("No form found");
    return;
  }
  const form = formPaymentsSDK.webflowFormIntance.form;
  if (!form) {
    console.error("No form found");
    return;
  }
  const successButtonText =
    formPaymentsSDK.webflowFormIntance.SubmitButtonSuccessText || "Success";
  const initialButtonText =
    formPaymentsSDK.webflowFormIntance.SubmitButtoninitialText || "Submit";
  formPaymentsSDK.on("paymentSuccess", () => {
    thind.form.success(form, successButtonText, true);
    thind.form.changeSubmitButton(form, initialButtonText, false);
    return;
  });
  formPaymentsSDK.on("paymentFailed", (data) => {
    thind.form.error(form, data.message || "Payment failed");
    thind.form.changeSubmitButton(form, initialButtonText, false);
    return;
  });
}

export function HandleWebflowAddressElements(
  element: HTMLElement,
  options: StripeAddressElementOptions,
  formPaymentsSDK: FormPaymentsSDK
) {
  formPaymentsSDK.createAddressElement(element, options);
}

export function HandleWebflowExpressElements(
  element: HTMLElement,
  options: StripeExpressCheckoutElementOptions,
  formPaymentsSDK: FormPaymentsSDK
) {
  //get price id from data attribute
  const priceId = formPaymentsSDK.webflowFormIntance.priceId;
  //check exsisting express element and remove it
  const existingElement =
    formPaymentsSDK.elements?.getElement("expressCheckout");
  if (existingElement) {
    existingElement.destroy();
  }
  if (!priceId) {
    console.error("No price id found");
    return;
  }
  const expressElement = formPaymentsSDK.createExpressCheckoutElement(
    element,
    options
  );
  listenToExpressElementUpdates(formPaymentsSDK, expressElement);
}

//Listen and handle updates on the express element
function listenToExpressElementUpdates(
  formPaymentsSDK: FormPaymentsSDK,
  element: StripeExpressCheckoutElement
) {
  // when user initiales or clicks on the express checkout button
  element.on("click", function (event) {
    if (
      !formPaymentsSDK.webflowFormIntance ||
      !formPaymentsSDK.webflowFormIntance.form
    ) {
      console.error("No form found");
      return;
    }
    const elementtype = event.expressPaymentType;
    let text = "Processing...";
    if (elementtype == "apple_pay") {
      text = "Processing Apple Pay Payment...";
    }
    if (elementtype == "google_pay") {
      text = "Processing Google Pay Payment...";
    }
    if (elementtype == "paypal") {
      text = "Processing PayPal Payment...";
    }
    if (elementtype == "link") {
      text = "Processing Link Payment...";
    }
    //show loading state
    thind.form.changeSubmitButton(
      formPaymentsSDK.webflowFormIntance.form,
      text,
      true
    );

    event.resolve();
  });

  //when express checkout is confirmed
  element.on("confirm", async function (event) {
    if (!formPaymentsSDK.stripe || !formPaymentsSDK.elements) {
      console.error("No stripe found");
      return;
    }
    if (!formPaymentsSDK.webflowFormIntance.form) {
      console.error("No form found");
      return;
    }
    //verify all required fields are filled
    if (!verifyRequiredFields(formPaymentsSDK.webflowFormIntance.form)) {
      thind.form.error(
        formPaymentsSDK.webflowFormIntance.form,
        "Please fill in all required fields"
      );
      event.paymentFailed({ reason: "fail" });
      return;
    }
    //create payment method
    // const paymentMethod = await formPaymentsSDK.createPaymentMethod(
    //   formPaymentsSDK.webflowFormIntance.billingAddress
    // );
    //create payment intent
    const metadata = createMetadata(formPaymentsSDK.webflowFormIntance.form);
    const serverPaymentIntent = await formPaymentsSDK.createPaymentIntent(
      metadata
    );

    if (serverPaymentIntent.error) {
      console.error(serverPaymentIntent.error);
      thind.form.error(
        formPaymentsSDK.webflowFormIntance.form,
        serverPaymentIntent.error
      );
      thind.form.changeSubmitButton(
        formPaymentsSDK.webflowFormIntance.form,
        formPaymentsSDK.webflowFormIntance.SubmitButtoninitialText || "Submit",
        true
      );
      return;
    }
    if (serverPaymentIntent.id) {
      thind.form.changeSubmitButton(
        formPaymentsSDK.webflowFormIntance.form,
        formPaymentsSDK.webflowFormIntance.SubmitButtonWaitingText ||
          "Processing...",
        true
      );
      //get shipping address
      formPaymentsSDK.getAddress();

      //confirm payment
      try {
        //get payment method from event
        await formPaymentsSDK.confirmPayment(
          serverPaymentIntent.client_secret,
          {
            return_url: window.location.href,
          }
        );
      } catch (error) {
        console.error(error);
        thind.form.error(
          formPaymentsSDK.webflowFormIntance.form,
          "Error creating payment intent"
        );
        thind.form.changeSubmitButton(
          formPaymentsSDK.webflowFormIntance.form,
          formPaymentsSDK.webflowFormIntance.SubmitButtoninitialText ||
            "Submit",
          false
        );
        event.paymentFailed({ reason: "fail" });
        return;
      }
    }
  });

  //on cancel
  element.on("cancel", function () {
    if (
      !formPaymentsSDK.webflowFormIntance ||
      !formPaymentsSDK.webflowFormIntance.form
    ) {
      console.error("No form found");
      return;
    }
    thind.form.changeSubmitButton(
      formPaymentsSDK.webflowFormIntance.form,
      formPaymentsSDK.webflowFormIntance.SubmitButtoninitialText || "Submit",
      false
    );
    thind.form.error(
      formPaymentsSDK.webflowFormIntance.form,
      "Payment cancelled! Make sure you have completed the form."
    );
  });
}
