import { StripeSdk } from "./stripe.ts";
import {
  StripeElements,
  StripeCardElementOptions,
  ConfirmCardPaymentData,
  PaymentIntentResult,
  StripeAddressElementOptions,
  StripeExpressCheckoutElementOptions,
  StripeAddressElement,
  ConfirmPaymentData,
  BillingDetails,
} from "@stripe/stripe-js";
import {
  getStripePrice,
  createPaymentIntent,
  confirmPaymentIntent,
} from "./api.ts";
import { Stripe } from "@stripe/stripe-js";
import {
  initStripeCardElements,
  initStripeAddressElement,
  initStripeExpressElements,
} from "./webflow/initWebflowForms.ts";
import {
  getBillingDetails,
  getShippingDetails,
} from "./webflow/webflowFormUtils.ts";
import { Thind } from "thind-js";

const thind = new Thind();

class SimpleEventEmitter {
  private listeners: { [event: string]: Array<(data: any) => void> } = {};

  on(event: string, listener: (data: any) => void): void {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(listener);
  }

  off(event: string, listener: (data: any) => void): void {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter(
        (l) => l !== listener
      );
    }
  }

  emit(event: string, data: any): void {
    if (this.listeners[event]) {
      this.listeners[event].forEach((listener) => listener(data));
    }
  }
}

/**
 * Represents a Webflow form instance with various properties related to payment processing.
 */
type WebflowFormInstance = {
  /**
   * The HTML form element associated with this instance.
   */
  form?: HTMLFormElement;
  /**
   * The type of payment element to use, either "card" or "paymentElement".
   */
  type: "card" | "paymentElement";
  /**
   * The type of price to use, either "manual" or "dynamic".
   */
  priceType?: "manual" | "dynamic";
  /**
   * The ID of the price to use.
   */
  priceId?: string;
  /**
   * The type of price payment to use, either "payment" or "subscription".
   */
  pricePaymentType?: "payment" | "subscription";
  /**
   * The currency of the price.
   */
  currency?: string;
  /**
   * The number of the price.
   */
  priceNumber?: number;
  /**
   * The description of the price.
   */
  paymentDescription?: string;
  /**
   * The initial text of the submit button.
   */
  SubmitButtoninitialText?: string;
  /**
   * The waiting text of the submit button.
   */
  SubmitButtonWaitingText?: string;
  /**
   * The success text of the submit button.
   */
  SubmitButtonSuccessText?: string;
  /**
   * The metadata of the payment.
   */
  metadata?: {
    [key: string]: string;
  };
  /**
   * The shipping address of the customer.
   */
  shippingAddress?: any;
  /**
   * The billing address of the customer.
   */
  billingAddress?: any;
};

export class FormPaymentsSDK {
  private StripeSdk: StripeSdk | null = null;
  private siteId: string | null = null;
  /**
   * The Stripe instance.
   */
  public stripe: Stripe | null = null;
  /**
   * The Stripe elements instance.
   */
  public elements: StripeElements | undefined;
  /**
   * The country code of the customer.
   */
  public countryCode: string | null = null;
  /**
   * The Webflow form instance with various properties related to payment processing.
   */
  public webflowFormIntance: WebflowFormInstance = {
    type: "card",
    priceType: "dynamic",
    SubmitButtoninitialText: "Submit",
    SubmitButtonWaitingText: "Submitting...",
    SubmitButtonSuccessText: "Success",
  };
  /**
   * The event emitter for the FormPaymentsSDK.
   */
  private eventEmitter: SimpleEventEmitter;
  /**
   * The constructor for the FormPaymentsSDK.
   */
  constructor() {
    this.eventEmitter = new SimpleEventEmitter();
  }
  /**
   * Initializes the FormPaymentsSDK with the given site ID.
   * @param siteId - The ID of the site to initialize the SDK for.
   * @returns The initialized FormPaymentsSDK.
   */
  public async initialize(siteId: string): Promise<FormPaymentsSDK> {
    try {
      this.StripeSdk = await StripeSdk.initialize(siteId);
      this.siteId = siteId;
      this.stripe = this.StripeSdk.stripe;
      this.elements = this.StripeSdk.elements;
      this.countryCode = await getCountryCode();
      await loadStripeElements(this);
      //check if url has payment_intent_id and payment_method_id
      const url = new URL(window.location.href);
      const paymentIntentId = url.searchParams.get("payment_intent");
      const payment_intent_client_secret = url.searchParams.get(
        "payment_intent_client_secret"
      );
      if (paymentIntentId && payment_intent_client_secret) {
        this.confirmPaymentIntent(paymentIntentId);
      }
      return this;
    } catch (error) {
      console.error("Failed to initialize FormPaymentsSDK:", error);
      throw error;
    }
  }

  /**
   * Retrieves the price details for the given price ID and updates the webflow form instance.
   * @param priceId - The ID of the price to retrieve.
   * @returns The price details.
   */
  public async getPrice(priceId: string): Promise<any> {
    if (!this.StripeSdk) {
      throw new Error("FormPayments not initialized");
    }
    if (!this.siteId) {
      throw new Error("FormPayments not initialized");
    }
    //return price if it is available in this.webflowFormIntance.priceId
    if (this.webflowFormIntance.priceId === priceId) {
      return this.webflowFormIntance;
    }
    const price = await getStripePrice(this.siteId, priceId);
    this.webflowFormIntance.priceNumber = price.unit_amount;
    this.webflowFormIntance.currency = price.currency;
    this.webflowFormIntance.paymentDescription = price.description;
    this.webflowFormIntance.priceType = "dynamic";
    this.webflowFormIntance.priceId = priceId;
    const priceType = price.type;
    if (priceType === "one_time") {
      this.webflowFormIntance.pricePaymentType = "payment";
    } else if (priceType === "recurring") {
      this.webflowFormIntance.pricePaymentType = "subscription";
    }
    this.updateStripeElement(
      this.webflowFormIntance.pricePaymentType as "payment" | "subscription",
      price.unit_amount,
      price.currency
    );
    return price;
  }

  /**
   * Sets the price amount for the payment.
   * @param amount - The amount of the price.
   * @param currency - The currency of the price.
   * @param description - The description of the price.
   */
  public async setPriceAmount(
    amount: number,
    currency: string,
    description?: string
  ): Promise<void> {
    if (!this.webflowFormIntance) {
      throw new Error("Webflow form not initialized");
    }
    if (description) {
      this.webflowFormIntance.paymentDescription = description;
    }
    this.webflowFormIntance.priceNumber = amount;
    this.webflowFormIntance.currency = currency;
    this.webflowFormIntance.priceType = "manual";
    this.updateStripeElement("payment", amount, currency);
  }

  /**
   * Sets the Stripe price for the payment.
   * @param priceId - The ID of the price to set.
   */
  public async setStripePrice(priceId: string): Promise<void> {
    if (!this.webflowFormIntance) {
      throw new Error("Webflow form not initialized");
    }
    const price = await this.getPrice(priceId);
    this.webflowFormIntance.priceId = priceId;
    this.webflowFormIntance.priceNumber = price.unit_amount;
    this.webflowFormIntance.currency = price.currency;
    this.webflowFormIntance.priceType = "dynamic";
    this.updateStripeElement(
      this.webflowFormIntance.pricePaymentType as "payment" | "subscription",
      price.unit_amount,
      price.currency
    );
  }

  /**
   * Updates the Stripe element with the given mode, amount, and currency.
   * @param mode - The mode of the payment.
   * @param amount - The amount of the payment.
   * @param currency - The currency of the payment.
   */
  private updateStripeElement(
    mode: "payment" | "setup" | "subscription",
    amount: number,
    currency: string
  ) {
    if (!this.webflowFormIntance) {
      throw new Error("Webflow form not initialized");
    }
    // const paymentMethodCreation = mode === "payment" ? undefined : "manual";

    /**
     * Updates the Stripe elements with the given mode, amount, and currency.
     * @param mode - The mode of the payment.
     * @param amount - The amount of the payment.
     * @param currency - The currency of the payment.
     */
    this.elements?.update({
      mode,
      amount,
      currency,
    });
    initStripeExpressElements(this);
  }

  /**
   * Creates a payment intent with the given metadata.
   * @param metadata - The metadata of the payment.
   * @returns The payment intent.
   */
  public async createPaymentIntent(metadata: {
    [key: string]: string;
  }): Promise<any> {
    if (!this.siteId) {
      throw new Error("Site not initialized");
    }
    if (!this.webflowFormIntance) {
      throw new Error("Webflow form not initialized");
    }
    if (!this.webflowFormIntance.priceNumber) {
      if (this.webflowFormIntance.form) {
        thind.form.error(this.webflowFormIntance.form, "Price not initialized");
      }
      throw new Error("Price not initialized");
    }
    if (!this.webflowFormIntance.currency) {
      if (this.webflowFormIntance.form) {
        thind.form.error(
          this.webflowFormIntance.form,
          "Currency not initialized"
        );
      }
      throw new Error("Currency not initialized");
    }
    return await createPaymentIntent(
      this.siteId,
      this.webflowFormIntance.priceNumber,
      this.webflowFormIntance.currency,
      this.webflowFormIntance.paymentDescription,
      metadata
    );
  }

  /**
   * Retrieves the address from the Stripe elements.
   * @returns The address.
   */
  public async getAddress(): Promise<any> {
    if (!this.StripeSdk) {
      throw new Error("FormPayments not initialized");
    }
    if (
      !this.webflowFormIntance.form ||
      !this.webflowFormIntance.SubmitButtonWaitingText ||
      !this.webflowFormIntance.SubmitButtoninitialText
    ) {
      throw new Error("Webflow form not initialized");
    }
    try {
      const addressElement = this.elements?.getElement(
        "address"
      ) as ExtendedStripeAddressElement | null;

      if (addressElement) {
        const value = await addressElement.getValue();
        const addressType = addressElement._componentMode;

        if (value.complete && addressType == "shipping") {
          this.webflowFormIntance.shippingAddress = value.value;
        } else if (value.complete && addressType == "billing") {
          this.webflowFormIntance.billingAddress = value.value;
        } else {
          this.webflowFormIntance.shippingAddress =
            getShippingDetails(this.webflowFormIntance.form) || null;
          this.webflowFormIntance.billingAddress =
            getBillingDetails(this.webflowFormIntance.form) || null;
          thind.form.error(
            this.webflowFormIntance.form,
            "Please fill in the shipping address"
          );
          thind.form.changeSubmitButton(
            this.webflowFormIntance.form,
            this.webflowFormIntance.SubmitButtonWaitingText,
            false
          );
          return;
        }
      }
    } catch (error) {
      console.error(error);
      thind.form.error(
        this.webflowFormIntance.form,
        "Error processing shipping address"
      );
      thind.form.changeSubmitButton(
        this.webflowFormIntance.form,
        this.webflowFormIntance.SubmitButtoninitialText,
        false
      );
      return;
    }
  }

  /**
   * Confirms the card payment with the given payment intent ID and data.
   * @param paymentIntentId - The ID of the payment intent to confirm.
   * @param data - The data to confirm the payment.
   * @returns The payment intent result.
   */
  public async confirmCardPayment(
    paymentIntentId: string,
    data: ConfirmCardPaymentData
  ): Promise<PaymentIntentResult> {
    if (!this.StripeSdk?.stripe) {
      throw new Error("FormPayments not initialized");
    }
    const { error, paymentIntent } =
      await this.StripeSdk.stripe.confirmCardPayment(paymentIntentId, data);
    if (error) {
      this.eventEmitter.emit("paymentFailed", error);
    }
    if (paymentIntent) {
      this.eventEmitter.emit("paymentSuccess", paymentIntent);
    }
    if (error) {
      return { error };
    }
    if (paymentIntent) {
      return { paymentIntent };
    }
    throw new Error(
      "Unexpected result: both error and paymentIntent are undefined"
    );
  }

  /**
   * Confirms the payment with the given client secret and data.
   * @param clientSecret - The client secret of the payment.
   * @param data - The data to confirm the payment.
   * @returns The payment result.
   */
  public async confirmPayment(clientSecret: string, data: ConfirmPaymentData) {
    if (!this.StripeSdk?.stripe) {
      throw new Error("FormPayments not initialized");
    }
    const result = await this.StripeSdk.stripe.confirmPayment({
      elements: this.StripeSdk.elements,
      clientSecret,
      confirmParams: data,
    });
    if (result.error) {
      this.eventEmitter.emit("paymentFailed", result.error);
    } else {
      this.eventEmitter.emit("paymentSuccess", result);
    }
    return result;
  }

  /**
   * Creates a payment method with the given billing details.
   * @param billingDetails - The billing details of the payment method.
   * @returns The payment method.
   */
  public async createPaymentMethod(billingDetails: BillingDetails) {
    if (!this.StripeSdk || !this.StripeSdk.stripe) {
      throw new Error("FormPayments not initialized");
    }
    return this.StripeSdk.stripe.createPaymentMethod({
      elements: this.StripeSdk.elements as StripeElements,
      params: {
        billing_details: billingDetails,
      },
    });
  }

  /**
   * Creates a card element with the given options.
   * @param element - The HTML element to create the card element for.
   * @param options - The options for the card element.
   * @returns The card element.
   */
  public createCardElement(
    element: HTMLElement,
    options?: StripeCardElementOptions
  ) {
    if (!this.StripeSdk) {
      throw new Error("FormPayments not initialized");
    }
    return this.StripeSdk.createCardElement(element, options);
  }

  /**
   * Creates an address element with the given options.
   * @param element - The HTML element to create the address element for.
   * @param options - The options for the address element.
   * @returns The address element.
   */
  public createAddressElement(
    element: HTMLElement,
    options?: StripeAddressElementOptions
  ) {
    if (!this.StripeSdk) {
      throw new Error("FormPayments not initialized");
    }
    return this.StripeSdk.createAddressElement(element, options);
  }

  /**
   * Creates an express checkout element with the given options.
   * @param element - The HTML element to create the express checkout element for.
   * @param options - The options for the express checkout element.
   * @returns The express checkout element.
   */
  public createExpressCheckoutElement(
    element: HTMLElement,
    options?: StripeExpressCheckoutElementOptions
  ) {
    if (!this.StripeSdk) {
      throw new Error("FormPayments not initialized");
    }
    return this.StripeSdk.createExpressCheckoutElement(element, options);
  }

  /**
   * Submits the stripe elements.
   * @returns The form submission result.
   */
  public submit() {
    if (!this.StripeSdk) {
      throw new Error("FormPayments not initialized");
    }
    return this.StripeSdk.submit();
  }

  //Listners and Emmiter
  public on(
    event: "paymentSuccess" | "paymentFailed",
    listener: (data: any) => void
  ): void {
    this.eventEmitter.on(event, listener);
  }

  public off(
    event: "paymentSuccess" | "paymentFailed",
    listener: (data: any) => void
  ): void {
    this.eventEmitter.off(event, listener);
  }

  /**
   * Confirms the payment intent with the given payment intent ID and client secret.
   * @param paymentIntentId - The ID of the payment intent to confirm.
   * @param payment_intent_client_secret - The client secret of the payment intent.
   * @returns The payment intent result.
   */
  private async confirmPaymentIntent(paymentIntentId: string) {
    if (!this.siteId) {
      throw new Error("Site not initialized");
    }
    const result = await confirmPaymentIntent(this.siteId, paymentIntentId);

    if (result.status === "succeeded") {
      this.eventEmitter.emit("paymentSuccess", result);
    } else {
      this.eventEmitter.emit("paymentFailed", result);
    }
    //remove payment_intent_id and payment_intent_client_secret from url
    const url = new URL(window.location.href);
    url.searchParams.delete("payment_intent");
    url.searchParams.delete("payment_intent_client_secret");
    window.history.replaceState({ path: url.href }, "", url.href);
    return result;
  }
}

/**
 * Retrieves the country code from the IP address.
 * @returns The country code.
 */
async function getCountryCode() {
  return fetch("https://ipapi.co/json/")
    .then((response) => response.json())
    .then((data) => {
      return data.country;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

/**
 * Loads the Stripe elements for the given form payments SDK.
 * @param formPaymentsSDK - The form payments SDK to load the elements for.
 */
async function loadStripeElements(formPaymentsSDK: FormPaymentsSDK) {
  await initStripeCardElements(formPaymentsSDK);
  await initStripeAddressElement(formPaymentsSDK);
}

/**
 * Extends the Stripe address element to add a component mode.
 */
interface ExtendedStripeAddressElement extends StripeAddressElement {
  _componentMode: string;
}
