import { FormPaymentsSDK } from "./src/sdk.ts";
export const formPaymentsSDK = new FormPaymentsSDK();
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://2e1c8c48bb29532180efc08eae69936c@o4507669644509184.ingest.us.sentry.io/4508131531096064",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // Optionally, if you want to ignore errors from other sources
  ignoreErrors: [
    // Add patterns for errors you want to ignore
    /^Script error\.?$/,
    /^Javascript error: Script error\.? on line 0$/,
  ],
});

document.addEventListener("DOMContentLoaded", async function () {
  const siteId = document.documentElement.getAttribute("data-wf-site") || "";
  if (siteId == "") {
    console.error("No site id found");
    return;
  }
  const sdk = await formPaymentsSDK.initialize(siteId);
  // Provide the SDK to the global window object
  window.formPaymentsSDK = sdk;
});
