const supportedProperties = {
  AccordionItem: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  Action: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "color",
    "fill",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
    "transition",
  ],
  Block: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "padding",
    "transition",
  ],
  BlockAction: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
    "transition",
  ],
  Button: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "color",
    "fill",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
    "transition",
  ],
  Checkbox: [
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  CheckboxInput: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "transition",
  ],
  CheckboxLabel: [
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  CodeInput: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "color",
    "fill",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
    "transition",
  ],
  Dropdown: ["border", "borderRadius", "boxShadow"],
  DropdownItem: [
    "backgroundColor",
    "border",
    "borderRadius",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  Error: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "color",
    "fill",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  Input: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "color",
    "fill",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
    "transition",
  ],
  InputCloseIcon: ["borderRadius", "boxShadow", "transition"],
  InputDivider: ["backgroundColor"],
  Label: [
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "opacity",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
    "transition",
  ],
  Link: [
    "borderRadius",
    "boxShadow",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  Menu: ["padding"],
  MenuAction: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
    "transition",
  ],
  MenuIcon: [
    "backgroundColor",
    "border",
    "borderRadius",
    "fill",
    "padding",
    "transition",
  ],
  PasscodeCloseIcon: ["borderRadius", "boxShadow", "transition"],
  PasscodeShowIcon: ["borderRadius", "boxShadow", "transition"],
  PickerAction: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  PickerItem: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
    "transition",
  ],
  RedirectText: [
    "border",
    "borderRadius",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  SecondaryLink: [
    "borderRadius",
    "boxShadow",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  Switch: [
    "backgroundColor",
    "border",
    "borderRadius",
    "fontSize",
    "transition",
  ],
  SwitchControl: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "fill",
    "transition",
  ],
  Tab: [
    "backgroundColor",
    "border",
    "borderRadius",
    "boxShadow",
    "color",
    "fill",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "margin",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
    "transition",
  ],
  TabIcon: ["fill", "padding", "transition"],
  TabLabel: [
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  TermsLink: [
    "borderRadius",
    "boxShadow",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  TermsText: [
    "border",
    "borderRadius",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
  ],
  Text: [
    "backgroundColor",
    "border",
    "borderRadius",
    "color",
    "fontFamily",
    "fontSize",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "padding",
    "textDecoration",
    "textShadow",
    "textTransform",
    "transition",
  ],
};

export function apperanceConfig(element: HTMLElement) {
  if (!element) {
    console.error("Element not found");
    return;
  }
  const computedStyle = window.getComputedStyle(element);
  function isPropertySupported(elementType: string, property: string) {
    return (
      (supportedProperties as Record<string, string[]>)[elementType]?.includes(
        property
      ) ?? false
    );
  }

  const theme = element.getAttribute("data-theme");
  const showLabels = element.getAttribute("data-show-labels") === "false";
  const labelStyles = Object.fromEntries(
    Object.entries(computedStyle).filter(([key]) =>
      isPropertySupported("Label", key)
    )
  );

  if (!showLabels) {
    labelStyles["display"] = "none";
  }

  const inputStyles = Object.fromEntries(
    Object.entries(computedStyle).filter(([key]) =>
      isPropertySupported("Input", key)
    )
  );

  return {
    theme: theme || "night",
    variables: {
      colorPrimary: "#006acc",
      colorBackground: "#000",
      colorText: "#fff",
      colorDanger: "#ff4d4f",
      fontFamily: "Inter, sans-serif",
    },

    rules: {
      ".Label": labelStyles,
      ".Input": inputStyles,
    },
  };
}
