// api.ts
export async function fetchFromServer(endpoint: string): Promise<any> {
  const response = await fetch(
    `${import.meta.env.VITE_SERVER_BASE_URL}${endpoint}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.json();
}

export async function postToServer(endpoint: string, data: any): Promise<any> {
  const response = await fetch(
    `${import.meta.env.VITE_SERVER_BASE_URL}${endpoint}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  return response.json();
}
